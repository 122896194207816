import { useState } from "react";
import exit_icon from '../../../assets/icons/exit.svg';
import { ReportsPageContainer, ReportsPageHeader, ExitContainer, TabItem, TabSelect } from './styled';
import Guide from "../../components/Guide";
import { FontGreyMediumRegular, FontInputGreyDarkRegular, FontWhiteRegular } from "../../../utils/typografyApp";
import { TabScripts } from "./TapScripts";
import { TabReports } from "./TabReports";

export const ReportsPage = () => {
    const tabItems = ['Roteiros', 'Laudos'];
    
    const [selectedTab, setSelectedTab] = useState('Roteiros');
    
    return (
        <ReportsPageContainer>
            <ReportsPageHeader>
                <Guide page='Roteiros' />

                <TabSelect>
                    {tabItems.map(item => (
                        <FontGreyMediumRegular>
                            <TabItem onClick={() => setSelectedTab(item)} active={item === selectedTab ? "true" : "false"}>
                                {item}
                            </TabItem>
                        </FontGreyMediumRegular>
                    ))}
                </TabSelect>

                <ExitContainer>
                    <FontWhiteRegular>Configurações de Cadastro</FontWhiteRegular>
                </ExitContainer>
            </ReportsPageHeader>

             {selectedTab === tabItems[0] && <TabScripts/> }
           {selectedTab === tabItems[1] && <TabReports/> }
           {/*  {selectedTab === tabItems[2] && <Tab3D/> }             */}

            

        </ReportsPageContainer>
    );
}