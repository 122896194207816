
import { useState } from "react";

export function useTabScriptsHook(){
    const [search, setSearch] = useState('');
    const [showNewModal, setShowNewModal] = useState(false);


    return{
        general: {
            showNewModal,
            setShowNewModal,
            colSpan: 6,
            search,
            setSearch,
        }
    }
}