import styled from "styled-components";

export const FontGreyDarkFullTitle = styled.h1`
    color: var(--grey-dark-color);
    margin: 0.5rem 0;
`; 

export const FontPrimaryMegaTitle = styled.span`
    color: var(--primary-color);
    font-family: "Ubuntu";
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    text-align: center;

    @media screen and (max-width: 1600px){
        font-size: 30px;
    }
`; 

export const FontPrimarySuperTitle = styled.span`
    color: var(--primary-color);
    font-family: "Ubuntu";
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    text-align: center;

    @media screen and (max-width: 1600px){
        font-size: 30px;
    }
`; 



export const FontGreyDarkMediumTitle = styled.span`
    color: var(--grey-dark-color);
    font-family: "Ubuntu";
    font-size: 1.25rem;
    font-weight: 500;
    line-height: 1.5rem;
`;

export const FontPrimaryMediumTitle = styled.span`
    color: var(--primary-color);
    font-family: "Ubuntu";
    font-size: 1.25rem;
    font-weight: 500;
    line-height: 1.5rem;
`;


export const FontPrimaryMediumSubTitle = styled.span`
    color: var(--primary-color);
    font-family: "Ubuntu";
    font-size: 0.875;
    font-weight: 500;
    line-height: 1.5rem;
`;


export const FontGreyDarkRegular = styled.span`
    color: var(--grey-dark-color);
    font-family: "Ubuntu";
    font-size: 0.625rem;
    font-weight: 400;
    line-height: 0.875rem;
`;

export const FontInputGreyDarkRegular = styled.div`
    input{
        color: var(--grey-dark-color);
        font-family: "Ubuntu";
        font-size: 0.875rem;
        font-weight: 400;
        line-height: 1rem;
    }

    span{
        font-family: "Ubuntu" !important;
    }
`;

export const FontGreyDarkBold = styled.span`
    color: var(--grey-dark-color);
    font-family: "Ubuntu";
    font-size: 0.875rem;
    font-weight: 700;
    line-height: 1rem;
`;

export const FontSmallGreyDarkBold = styled.span`
    color: var(--grey-dark-color);
    font-family: "Ubuntu";
    font-size: 0.625rem;
    font-weight: 700;
    line-height: 1rem;
`;

export const FontRedBold = styled.span`
    color: var(--red-2-color);
    font-family: "Ubuntu";
    font-size: 0.875rem;
    font-weight: 700;
    line-height: 1rem;
`;

export const FontRedRegular = styled.span`
    color: var(--red-2-color);
    font-family: "Ubuntu";
    font-size: 0.875rem;
    font-weight: 500;
    line-height: 1rem;
`;

export const FontGreyMediumRegular = styled.span`
    color: var(--grey-medium-color);
    font-family: "Ubuntu";
    font-size: 0.625rem;
    font-weight: 400;
    line-height: 0.875rem;
`;

export const FontWhiteRegular = styled.span`
    color: var(--white-color);
    font-family: "Ubuntu";
    font-size: 0.625rem;
    font-weight: 400;
    line-height: 0.875rem;
`;

export const FontGreyDarkFullRegular = styled.span`
    color: var(--grey-dark-color);
    font-family: "Ubuntu";
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1rem;
`;

export const FontGreyDarkFullBold = styled.span`
    color: var(--grey-dark-color);
    font-family: "Ubuntu";
    font-size: 0.875rem;
    font-weight: 700;
    line-height: 1rem;
`;

export const FontPoppinsGreyDarkSmallRegular = styled.span`
    font-family: 'Poppins' !important;
    color: var(--grey-dark-color);
    font-family: "Ubuntu";
    font-size: 0.75rem;
    font-weight: 400;
    line-height: 1rem;
`;

export const FontGreyDarkSemiMedium = styled.span`
    color: var(--grey-dark-color);
    font-family: "Ubuntu";
    font-size: 0.625rem;
    font-weight: 500;
    line-height: 0.875rem;
`;

export const FontGreyMediumSemiMedium = styled.span`
    color: var(--grey-medium-color);
    font-family: "Ubuntu";
    font-size: 0.625rem;
    font-weight: 500;
    line-height: 0.875rem;
`;


export const FontGreyDarkMedium = styled.span`
    color: var(--grey-dark-color);
    font-family: "Ubuntu";
    font-size: 0.875rem;
    font-weight: 500;
    line-height: 1rem;
`;

export const FontGreyDarkSemiBold = styled.span`
    color: var(--grey-dark-color);
    font-family: "Ubuntu";
    font-size: 0.857rem;
    font-weight: 700;
    line-height: 1rem;
`;

export const FontButtonGreyLightSemiBold = styled.span`
    color: var(--grey-light-color);
    font-family: "Ubuntu";
    font-size: 1rem;
    font-weight: 700;
    line-height: 1.25rem;
`;

export const FontButtonGreyLightMedium= styled.span`
    color: var(--grey-light-color);
    font-family: "Ubuntu";
    font-size: 0.875rem;
    font-weight: 500;
`;







