
import { ReportsDetailsContainer, ReportsDetailsFilters } from "./styled"
import { useReportsDetailsHook } from "./hook";
import { ReportsPageHeader } from "../styled";
import Guide from "../../../components/Guide";
import inspetor_image from '../../../../assets/inspetor.png';
import logo_tau from '../../../../assets/logo_tau.svg';
import logo_jonnpo from '../../../../assets/logo_jonnpo.png';
import print_icon from '../../../../assets/icons/print.svg';
import { FontGreyDarkFullRegular, FontGreyDarkSemiMedium } from "../../../../utils/typografyApp";



export const ReportsDetailsPage = () => {
    const hook = useReportsDetailsHook();
    return (
        <ReportsDetailsContainer>
            <ReportsPageHeader>
                <Guide />
            </ReportsPageHeader>
            <FontGreyDarkFullRegular>
                <div className="top-header">
                    <div className="data">
                        <span className="title">Laudo de inspeção</span>
                        <div className="circle"></div>
                        <span className="number">20231012883</span>
                        <div className="circle"></div>
                        <span className="user">
                            <img className="image_inspector" src={inspetor_image} alt="Foto do inspetor" />
                            {'Bruno Carvalho'}
                        </span>
                        <div className="circle"></div>
                        <span className="asset">Portainer I</span>
                    </div>
                    <div className="print">
                        <img src={print_icon} alt="Imprimir" />
                        <span>Imprimir</span>
                    </div>
                </div>

                <div className="content">
                    <div className="row between">
                        <div className="col">
                            <div className="col">
                                <h3> Dados do cadastro</h3>
                                <div className="col data">
                                    <div className="row between border">
                                        <span>Nº do laudo de inspeção</span>
                                        <span className="bold">20231012883</span>
                                    </div>

                                    <div className="row between border">
                                        <span>Inspetor Responsável</span>
                                        <span className="bold">Bruno Carvalho</span>
                                    </div>

                                    <div className="row between border">
                                        <span>Ativo</span>
                                        <span className="bold">Portainer I</span>
                                    </div>

                                    <div className="row between border">
                                        <span>Data do laudo</span>
                                        <span className="bold">17/10/2023</span>
                                    </div>

                                    <div className="row between border">
                                        <span>Ordem inspeção de origem</span>
                                        <span className="bold">20231012883</span>
                                    </div>

                                    <div className="row between border">
                                        <span>Data da inspeção</span>
                                        <span className="bold">20/12/2023</span>
                                    </div>
                                </div>

                            </div>

                            <div className="col">
                                <h3>Resumo do laudo de inspeção</h3>
                                <div className="col data">
                                    <div className="row between border">
                                        <span>Área total degradada</span>
                                        <span className="bold">700m²</span>
                                    </div>

                                    <div className="row between border">
                                        <span>Prioridade laudo</span>
                                        <span className="bold">Alta</span>
                                    </div>

                                    <div className="row between border">
                                        <span>Data planejada para conclusão</span>
                                        <span className="bold">10/12/2023</span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col">
                            <div className="row">
                                <div className="row">
                                <div className="col align-center">
                                    <div className="img">
                                        <img src={logo_tau} />
                                    </div>
                                    <span>Gestão de ativos</span>
                                    <span>12345687465.0001/01</span>
                                </div>

                                <div className="col align-center">
                                    <div className="img">
                                        <img src={logo_jonnpo} />
                                    </div>
                                    <span>Jonnpo Tecnologia</span>
                                    <span>12345687465.0001/01</span>
                                </div>
                                </div>
                                <div className="col"></div>
                            </div>

                        </div>
                    </div>
                </div>

            </FontGreyDarkFullRegular>
        </ReportsDetailsContainer >
    )
}