import { useState } from "react";
import { useNavigate } from "react-router-dom";

export function useCardReportHook(){
    const tabItems = ['Produtividade', 'Ativos', 'Financeiro'];
    const [selectedTab, setSelectedTab] = useState('Produtividade');
    const navigate = useNavigate();

    return{
        tabItems,
        selectedTab,
        setSelectedTab,
        navigate
    }

}