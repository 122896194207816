import { styled } from 'styled-components';

export const Main = styled.main`
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;

    font-family: var(--font-family-primary);
    color: var(--grey-dark-color);

    .button-inpessao{
        position: absolute;
        bottom: 0;
        left: 0;
        margin: 20px;

        button{
            padding: 8px 16px;
            background-color: #2296ea;
            color: white;
            border-radius: 8px;
        }
    }
    

    button{
        cursor: pointer;
        padding: 0;
        background-color: transparent;
        border: none;

        &:hover{
            opacity: 80%;
        }
    }
`;
