
import { useState } from "react";
import { useNavigate } from "react-router-dom";

export function useTabReportsHook(){
    const [search, setSearch] = useState('');
    const [showNewModal, setShowNewModal] = useState(false);
    const navigate = useNavigate();

    return{
        general: {
            showNewModal,
            setShowNewModal,
            colSpan: 6,
            search,
            setSearch,
            navigate
        }
    }
}