import { useForm } from "react-hook-form";

export function useNewScriptModalHook({onClose, selectedScript}){

    const userForm = useForm({ });

    function onSubmit(payload) {
    }

    return{
        form: {
            onSubmit,
            userForm
        }
    }
}