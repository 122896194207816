import { NewScriptForm } from "./styled";
import { useNewScriptModalHook } from "./hook";
import { FormProvider } from "react-hook-form";
import { ModalMain } from "../../../../components/Modals/styled";
import { FontGreyDarkMediumTitle } from "../../../../../utils/typografyApp";
import SecondaryButton from "../../../../components/SecondaryButton";
import PrimaryButton from "../../../../components/PrimaryButton";
import Input from "../../../../components/Input";
import Select from "../../../../components/Select";

const NewScriptModal = ({ onClose, selectedScript }) => {

    const hook = useNewScriptModalHook({ onClose, selectedScript });

    return (
        <ModalMain>
            <FormProvider {...hook.form.userForm}>

                <NewScriptForm>
                    <form onSubmit={hook.form.userForm.handleSubmit(hook.form.onSubmit)}>
                        <div className={"menu-content"}>

                            <div className="content">
                                <div className="title">
                                    <FontGreyDarkMediumTitle>Novo Roteiro</FontGreyDarkMediumTitle>
                                </div>

                                <div className="form-input">
                                    <div className="form-group">

                                        <Input type="number" placeholder="Nº do Roteiro" name="cpf" />
                                        <Input type="date" placeholder="Data" name="date" />
                                    </div>
                                    <div className="form-group">
                                        <Select
                                            type="text"
                                            placeholder="Selecione o inspetor responsável"
                                            name="fkDefectTypeId"
                                            options={[]}
                                            defaultValue={''}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <Select
                                            type="text"
                                            placeholder="Prioridade"
                                            name="fkDefectTypeId"
                                            options={[]}
                                            defaultValue={''}
                                        />
                                    </div>

                                    <div className="form-group">
                                        <Input type="date" placeholder="Data da conclusão desejada" name="date" />
                                    </div>

                                    <div className="form-group">
                                        <Select
                                            type="text"
                                            placeholder="Selecione o Ativo"
                                            name="fkDefectTypeId"
                                            options={[]}
                                            defaultValue={''}
                                        />
                                    </div>


                                </div>
                            </div>

                            <div className="actions-container">
                                <SecondaryButton disabled={hook.form.isLoading} onClick={onClose}>Voltar</SecondaryButton>
                                <PrimaryButton disabled={hook.form.isLoading} type={'submit'}> {hook.form.isLoading ? 'Salvando...' : selectedScript ? 'Editar' : 'Avançar'} </PrimaryButton>
                            </div>
                        </div>
                    </form>
                </NewScriptForm>
            </FormProvider>
        </ModalMain>
    );
}

export default NewScriptModal;