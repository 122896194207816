import styled from "styled-components";

export const ReportsDetailsContainer = styled.div`
    width: 100vw;
    height: 100vh;
    overflow-y: scroll;
    background: #FFF;

    .content{
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 16px;
        padding: 0 50px;

        box-sizing: border-box;

        .data{
            padding: 0 10px;
            box-sizing: border-box;
        }

        .row{
            width: 100%;
            display: flex;
            gap: 42px;
            box-sizing: border-box;            
        }

        .align-center{
            align-items: center;
        }

        .bold{
            font-weight: 700;
            
        }

        .between{
            justify-content: space-between;
            width: 100%;
            box-sizing: border-box;
        }

        .border{
            border: 1px solid #E6E4E0;
            border-radius: 8px;
            padding: 10px;
        }

        .col{
            display: flex;
            flex-direction: column;
            gap: 8px;
            width: 100%;
            box-sizing: border-box;

            
        }

        .img{
            width: 158px;
            height: 158px;
            display: flex;
            align-items: center;
            justify-content: center;

            img{
                width: 96px;
            }
        }

    }

    .top-header{
        display: flex;
        justify-content: space-between;
        padding: 16px;
        align-items: center;
        margin: 40px 40px 10px 40px;
        padding: 10px;
        border-bottom: 1px solid #A8A8A8;

        .title{
            font-weight: 800;
        }

        .print{
            display: flex;
            gap: 10px;
            align-items: center;
            cursor: pointer;
        }

        .circle{
            width: 7px;
            height: 7px;
            border-radius: 50%;
            background-color: #969696;
        }
        

        .data{
            display: flex;
            gap: 16px;
            align-items: center;

            .user{
                display: flex;
                gap: 4px;
                align-items: center;
                .image_inspector{
                    width: 24px;
                    height: 24px;
                    border-radius: 50%;
                }
            }
        }
    }
`;