import styled from "styled-components";

export const ButtonsContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 1rem;
`;

export const ButtonRegister = styled.button`
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 0.75rem;
`;

export const NewScriptForm = styled.div`
    width: 520px;
    .form-input{
        width: 465px;
        display: flex;
        flex-direction: column;
        gap: 22px;
    }

    .form-group{
        display: flex;
        gap: 16px;
    }
    
    form{
        display: flex;
        flex-direction: column;
        gap: 22px;

        .profilePic{
            cursor : pointer;
            img{
                width: 64px;
                height: 64px;
                object-fit: cover;
                border-radius: 9px;
            }   
        }
    }
`;