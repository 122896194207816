
import { TabScriptsContainer, TabScriptsFilters } from "./styled"
import add_icon from '../../../../assets/icons/add.svg';
import search_icon from '../../../../assets/icons/search.svg';
import Input from "../../../components/Input";
import edit_icon from '../../../../assets/icons/edit.svg';
import { FontGreyDarkBold } from "../../../../utils/typografyApp";
import AdmButton from "../../../components/AdmButton";
import { Loading } from "../../../components/Loading";
import { useTabScriptsHook } from "./hook";
import NewScriptModal from "./NewScriptModal";

import inspetor_image from "../../../../assets/inspetor.png";

export const TabScripts = () => {
    const hook = useTabScriptsHook();
    return (
        <TabScriptsContainer>
            <FontGreyDarkBold>
                <table>
                    <thead>
                        <tr>
                            <th>Nº do roteiro</th>
                            <th>Data</th>
                            <th>Prioridade</th>
                            <th>Etapa</th>
                            <th>Status</th>
                            <th>Inspetor Responsável</th>
                        </tr>

                    </thead>

                    <tbody>
                        <tr>
                            <td colSpan={hook.general.colSpan}>
                                <TabScriptsFilters>
                                    <Input className={'search'} icon={search_icon} placeholder={'Buscar roteiro'} setSearch={hook.general.setSearch}/>
                                    <AdmButton 
                                        // onClick={() => { hook.general.setShowNewModal(true) }}
                                    >
                                        <img src={add_icon} alt="Icone de adicionar" />
                                        Novo Roteiro
                                    </AdmButton>
                                </TabScriptsFilters>
                            </td>
                        </tr>

                        {/* {hook.users.userData?.count === 0 || hook.users.isLoading ?
                            <tr>
                                <td colSpan={hook.general.colSpan} className="empty-table">
                                    {hook.users.isLoading ?
                                        <Loading /> :
                                        <div>Nenhum roteiro cadastrado</div>
                                    }
                                </td>
                            </tr> : */}
                            <>
                                {Array.from({length: 5}).map(script => (
                                    <tr className="click">
                                        
                                        <td>{'20231012883'}</td>
                                        <td>{'12/06/2020'}</td>
                                        <td>{'Alta'}</td>
                                        <td>{'Inspetor em campo'}</td>
                                        <td>{'Dados coletados, '}<b>{'12/06/2020'}</b></td>
                                        <td>
                                            <div className="flex">
                                                <img className="image_inspector" src={inspetor_image} alt="Foto do inspetor"/>
                                                {'Bruno Carvalho'}
                                            </div>
                                        </td>
                                    </tr>
                                ))}

                            </>
                        {/* } */}

                    </tbody>
                </table>
            </FontGreyDarkBold>

            {hook.general.showNewModal && <NewScriptModal/>}
        </TabScriptsContainer>
    )
}