
import { TabReportsContainer, TabReportsFilters } from "./styled"
import add_icon from '../../../../assets/icons/add.svg';
import search_icon from '../../../../assets/icons/search.svg';
import Input from "../../../components/Input";
import { FontGreyDarkBold } from "../../../../utils/typografyApp";
import AdmButton from "../../../components/AdmButton";
import { useTabReportsHook } from "./hook";
// import NewReportModal from "./NewReportModal";
// import dpf from '../../../../assets/tal.pdf';
import print_icon from '../../../../assets/icons/print.svg';

import inspetor_image from "../../../../assets/inspetor.png";

export const TabReports = () => {
    const hook = useTabReportsHook();
    return (
        <TabReportsContainer>
            <FontGreyDarkBold>
                <table>
                    <thead>
                        <tr>
                            <th>Nº do laudo</th>
                            <th>Data</th>
                            <th>Prioridade</th>
                            <th>Etapa</th>
                            <th>Status</th>
                            <th>Inspetor Responsável</th>
                            <th></th>
                        </tr>

                    </thead>

                    <tbody>
                        <tr>
                            <td colSpan={hook.general.colSpan}>
                                <TabReportsFilters>
                                    <Input className={'search'} icon={search_icon} placeholder={'Buscar laudo'} setSearch={hook.general.setSearch}/>
                                    {/* <AdmButton onClick={() => { hook.general.setShowNewModal(true) }}>
                                        <img src={add_icon} alt="Icone de adicionar" />
                                        Novo laudo
                                    </AdmButton> */}
                                </TabReportsFilters>
                            </td>
                        </tr>

                        {/* {hook.users.userData?.count === 0 || hook.users.isLoading ?
                            <tr>
                                <td colSpan={hook.general.colSpan} className="empty-table">
                                    {hook.users.isLoading ?
                                        <Loading /> :
                                        <div>Nenhum laudo cadastrado</div>
                                    }
                                </td>
                            </tr> : */}
                            <>
                                {Array.from({length: 7}).map(report => (
                                    <tr className="click" 
                                        // onClick={()=>hook.general.navigate('/reports/1')}
                                    >
                                        
                                        <td>{'20231012883'}</td>
                                        <td>{'12/06/2020'}</td>
                                        <td>{'Alta'}</td>
                                        <td>{'Inspetor em campo'}</td>
                                        <td>{'Dados coletados, '}<b>{'12/06/2020'}</b></td>
                                        <td>
                                            <div className="flex">
                                                <img className="image_inspector" src={inspetor_image} alt="Foto do inspetor"/>
                                                {'Bruno Carvalho'}
                                            </div>
                                        </td>
                                        <td>
                                            <img src={print_icon} alt="Icone de impressão" onClick={()=>window.open('/relatorio.pdf')}/>
                                        </td>
                                    </tr>
                                ))}

                            </>
                        {/* } */}

                    </tbody>
                </table>
            </FontGreyDarkBold>

            {/* {hook.general.showNewModal && <NewReportModal/>} */}
        </TabReportsContainer>
    )
}